/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/law%2F88160685b8055d481e4638e513511df.jpg",
    alt: "营业执照"
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/law/0e67b0dad21f62c935a604daf322c66.jpg",
    alt: "食品安全许可证"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
